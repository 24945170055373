html {
  font-size: 62.5%; // 1rem = 10px
  height: 100%;
}

body {
  font-size: 1.6rem; // 16px default font
  line-height: 1.333em;
  height: 100%;
}

* {
  // Set this for sanity
  box-sizing: border-box;
}
