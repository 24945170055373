#viewport {}

.viewport-region--sidebar-collapsed {
  @include breakpoint('M+') {
    margin-left: $dimension-sidebar-collapsed-width;
  }
}

.viewport-region--sidebar-expanded {
  @include breakpoint('M+') {
    margin-left: $dimension-sidebar-width;
  }
}

.viewport-region--sidebar-none {
  @include breakpoint('M+') {
    margin-left: 0;
  }
}

// IE11 doesn't treat main as block element
// which causes problems
main {
  display: block;
}
