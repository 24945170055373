
.full-width {
  width: 100%;
}
.mat-mdc-form-field .mdc-text-field {
  padding: 0;
}
.mat-mdc-dialog-surface {
  padding: 24px;
}
