$color-primary: #0078b9;
$color-secondary: #a6a6a6;
$color-disabled: #dfe2e7;
$color-font: #465563;
$color-alert: #d0021b;
$color-light: #ffffff;

// Angular material theme colors
$black-87-opacity: rgba(black, 0.87);
$white-87-opacity: rgba(white, 0.87);

$color-material-primary: (
  50: #e0eef6,
  100: #b3d6ea,
  200: #80bbdc,
  300: #4ca0cd,
  400: #268cc3,
  500: #0078b9,
  600: #006BA6,
  700: #00598a,
  800: #00486f,
  900: #003b5c,
  A100: #75c0eb,
  A200: #4aa6dc,
  A400: #007ec2,
  A700: #0a4c92,

  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: white,
    A400: white,
    A700: white,
  )
);

$color-material-accent: (
  50: #fce4d2,
  100: #fbd7bc,
  200: #f9bd8f,
  300: #f7a362,
  400: #f58f41,
  500: #f47c20,
  600: #f2721b,
  700: #f06716,
  800: #ee5910,
  900: #eb4909,
  A100: #fab37d,
  A200: #fa9c55,
  A400: #f57b1d,
  A700: #f05800,

  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: $black-87-opacity,
    600: $black-87-opacity,
    700: $black-87-opacity,
    800: white,
    900: white,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: black,
  )
);

$color-material-grey: #9E9E9E;

// THE FOLLOWING ARE OLD

// Stock colors
$color-tone: #181818;
$color-tone-light: #eee;

// Brand colors
$color-blue: #38a7d2;
$color-blue-light: #9dd0e5;
$color-blue-dark: #1c70a2;
$color-green: #98c75e;
$color-green-light: #c0da94;
$color-green-dark: #5a9341;
$color-yellow: #fad031;
$color-yellow-light: #ffe79a;
$color-yellow-dark: #d7a72d;
$color-orange: #e6742b;
$color-orange-light: #f1af4c;
$color-orange-dark: #b84d2c;
$color-red: #b4242b;
$color-red-light: #e46d5f;
$color-red-dark: #731e21;
$color-pink: #9f3965;
$color-pink-light: #d96098;
$color-pink-dark: #702347;
$color-purple: #4b2a47;
$color-purple-light: #8f5894;
$color-purple-dark: #281827;
$color-slate: #6a737b;
$color-slate-light: #a5b1b7;
$color-slate-dark: #424e56;

// from deprecated web components
$color-primary: #00aeff;
$color-grey-1: #465563;
$color-grey-2: #a2aab1;
$color-grey-3: #e4e6e8;
$color-grey-4: #f6f6f7;
$color-light: #fff;
$color-alert: #d0021b;
$color-away: #f0d339;
$color-active: #85d84e;
$color-green-1: #6e9830;
$color-green-2: #a0cc5e;
$color-black: #000;
$color-orange: #f47c20;

$color-lavender: #475ec1;

$color-font: $color-grey-1;
$color-secondary: $color-grey-2;
$color-disabled: $color-grey-3;

$color-adapt-blue-1: #006BA6;
$color-adapt-blue-2: #00598A;
$color-adapt-blue-3: #004D77;
