@use '@angular/material' as mat;

@include mat.core();
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// @include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://www.google.com/design/spec/style/color.html
$adapt-app-primary: mat.m2-define-palette($color-material-primary);
$adapt-app-accent:  mat.m2-define-palette($color-material-accent, A200, A100, A400);

// Create the theme object (a Sass map containing all of the palettes).
// $adapt-app-theme:  mat.define-light-theme($adapt-app-primary, $adapt-app-accent);
// $adapt-app-all-blue-theme:  mat.define-light-theme($adapt-app-primary, $adapt-app-primary);

// The warn palette is optional (defaults to red).
$theme-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$theme: mat.m2-define-light-theme((
  color: (
    primary: $adapt-app-primary,
    accent: $adapt-app-accent,
    warn: $theme-warn,
  ),
  typography: mat.m2-define-typography-config()
));

$checkBoxTheme: mat.m2-define-light-theme((
  color: (
    primary: $adapt-app-primary,
    accent: $adapt-app-primary,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($theme);

@include mat.checkbox-theme($checkBoxTheme);



:root{
    --mdc-filled-text-field-container-color: #fff;
}

.mat-mdc-tab-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.mat-mdc-form-field-focus-overlay {
    background-color: #fff;
}
.mat-mdc-tab-nav-panel {
  overflow-y: scroll;
}
.cdk-overlay-pane .mat-mdc-menu-panel{
  --mat-menu-container-shape: 0px;
  .mat-mdc-menu-content {
    padding: 0px;
  }
}
