// @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,600,700&subset=latin-ext');
@import "@fontsource/source-sans-pro/400.css";
@import "@fontsource/source-sans-pro/400-italic.css";
@import "@fontsource/source-sans-pro/600.css";
@import "@fontsource/source-sans-pro/700.css";
// @import url('https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,400i,600,700&subset=latin-ext');
@import '@fontsource/roboto/latin-ext-100.css';
@import '@fontsource/roboto/latin-ext-300.css';
@import '@fontsource/roboto/latin-ext-400.css';
@import '@fontsource/roboto/latin-ext-500.css';
@import '@fontsource/roboto/latin-ext-700.css';

// @import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import "@fontsource/material-icons";
@import 'core.scss';

@import 'material-theme.scss';

body {
  background: $color-disabled;
  color: $color-font;
  font-family: $font-primary;
  letter-spacing: 0.025em;
  -webkit-font-smoothing: antialiased;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  font-size: 24px;
  vertical-align: middle;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
