// Function doing something with a key/value pair from a map
// ---
// @param [string] $breakpoint: see styles/settings/_breakpoints
// @param [argList] $queries: strings - e.g '(max-height: 99px)', '(orientation: portrait)'
// ---
@mixin breakpoint($breakpoint: $default-breakpoint, $queries...) {
    $primary: map-get($mq-breakpoints, $breakpoint);
    $secondary: '';

    @if length($queries) > 0 {
        @each $query in $queries {
            $secondary: $secondary + ' and ' + $query;
        }
    }

    @if $primary != null {
        @media #{ $primary + $secondary } { @content; }
    } @else {
        @warn 'Invalid breakpoint `#{$breakpoint}`.';
    }
}
