.sidebar-region {
  position: fixed;
  display: block;
  top: 0;
  bottom: 0;
  left: -100%;
  z-index: 20;

  @include breakpoint('S') {
    transition: left 0.3s;
    width: 100%;
  }

  @include breakpoint('M+') {
    left: 0;
    margin-top: $dimension-navbar-height;
  }
}

.sidebar-region--open {
  @include breakpoint('S') {
    pointer-events: all;
    left: 0;
  }
}

.sidebar-region--closed {
  @include breakpoint('S') {
    left: -100%;
    pointer-events: none;
  }
}

.sidebar-region--collapsed {
  @include breakpoint('M+') {
    width: $dimension-sidebar-collapsed-width;
  }
}

.sidebar-region--expanded {
  @include breakpoint('M+') {
    width: $dimension-sidebar-width;
  }
}
