#app-container {
  position: fixed;
  top: $dimension-navbar-height;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;

  .viewport-region--sidebar-expanded & {
    left: $dimension-sidebar-width;
    @include breakpoint('S') {
      left: 0;
    }
  }

  .viewport-region--sidebar-collapsed & {
    left: $dimension-sidebar-collapsed-width;
    @include breakpoint('S') {
      left: 0;
    }
  }

  .viewport-region--sidebar-none & {
    left: 0;
  }

  @include breakpoint('M+') {
    top: $dimension-navbar-height;
  }
}
