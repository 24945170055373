/* ==========================================================================
                                MATERIAL COLOUR PALETTE
========================================================================== */
$black-87-opacity: rgba(black, 0.87);
$white-87-opacity: rgba(white, 0.87);

$repl-blue: (
50: #e0eef6,
100: #b3d6ea,
200: #80bbdc,
300: #4ca0cd,
400: #268cc3,
500: #0078b9,
600: #00669d,
700: #00598a,
800: #00486f,
900: #003b5c,
A100: #75c0eb,
A200: #4aa6dc,
A400: #007ec2,
A700: #0a4c92,
contrast:
(
50: $black-87-opacity,
100: $black-87-opacity,
200: $black-87-opacity,
300: $black-87-opacity,
400: $black-87-opacity,
500: white,
600: white,
700: $white-87-opacity,
800: $white-87-opacity,
900: $white-87-opacity,
A100: $black-87-opacity,
A200: white,
A400: white,
A700: white,
)
);

$repl-orange: (
50: #fce4d2,
100: #fbd7bc,
200: #f9bd8f,
300: #f7a362,
400: #f58f41,
500: rgb(237, 120, 2),
600: #f2721b,
700: #f06716,
800: #ee5910,
900: #eb4909,
A100: #fab37d,
A200: #fa9c55,
A400: #f57b1d,
A700: #f05800,
contrast:
(
50: $black-87-opacity,
100: $black-87-opacity,
200: $black-87-opacity,
300: $black-87-opacity,
400: $black-87-opacity,
500: white,
600: white,
700: $white-87-opacity,
800: $white-87-opacity,
900: $white-87-opacity,
A100: $black-87-opacity,
A200: white,
A400: white,
A700: white,
)
);

@function get-theme-colour($key: 500) {
  @return map-get($repl-blue, $key);
}


@import '../../projects/adapt-web-core/src/core.scss';

@import 'settings/dimensions.scss';