
.splash-screen {
  background-color: #006BA6;
  display: table;
  height: 100%;
  top: 0;
  position: fixed;
  z-index: 10000;
  width: 100%; }

.splash-screen__content {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.splash-screen__logo {
  width: 544.15px;
  height: 89.55px;
}

.splash-screen__text {
  font-size: 4rem;
  font-weight: 300;
  color: white;
  line-height: 7rem;
  vertical-align: bottom;
  padding-left: 2rem;
  font-family: 'Roboto', 'Verdana', 'Arial';
}

@media (min-width: 768px) {
  .splash-screen__logo {
    height: 10rem;
    width: 35rem;
  }
}

@media (min-width: 1440px) {
  .splash-screen__logo {
    height: 12rem;
    width: 40rem;
  }
}

.splash-screen__loader {
  margin: 0 auto;
  margin-top: 5rem;
  width: 5rem;
  height: 5rem;
  display: block;
  text-align: center;
}

.splash-adapt-loading-container {
  animation: splash-adapt-rotate 2s linear infinite;
  transform-origin: center center;
  margin: auto;
}

.splash-adapt-loading-path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: splash-adapt-dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
  stroke: #fff;
}

@keyframes splash-adapt-rotate {
  100% {
      transform: rotate(360deg);
  }
}

@keyframes splash-adapt-dash {
  0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
  }
  50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
  }
  100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
  }
}
