// @import '../projects/adapt-web-core/src/core.scss';
// @import '../projects/adapt-web-core/src/styles/settings/font-sizes.scss';
// @import '../projects/adapt-web-core/src/styles/settings/colors.scss';


 

@import 'styles/variables';
@import '../projects/adapt-web-core/src/splash.css';
@import 'styles/custom';
@import '../projects/adapt-web-core/src/web-core.scss';
@import 'styles/layout/index.scss';

// ^ Help SASS globbing find this project again... because ¯\_(ツ)_/¯


